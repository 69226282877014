export const environment = {
  production: false,
  name: 'development',
  apiURL: 'https://api-dev.lyzi.fr/api/next/',
  loyaltyApiURL: 'https://api-dev.lyzi.fr/loyalty/api/',
  googleMapsApiKey: 'AIzaSyDCNFD3sZ297Qc2FHpG94lEbfjdEAAPJJg',
  stripePublicKey: 'pk_test_nmvkrnE4fvD5z8Tgy4dPZO7O000wmKGkP1',
  appVersion: '2.42.5',
  brokerApiURL: 'https://stage-gpos-licence.digital-broker.io',
  zebitexApiURL: 'https://stage-gpos-licence.digital-broker.io/api/v1',
  firebase: {projectId:"zbxwallet",appId:"1:939646386932:web:c581b8269f050f2f0ed4a1",storageBucket:"zbxwallet.appspot.com",apiKey:"AIzaSyDmRJkEd40VTgp2JghwmjpOmhgUrV1cc_A",authDomain:"zbxwallet.firebaseapp.com",messagingSenderId:"939646386932",measurementId:"G-T1LJEPSTP0"},
  lyziCryptoSecret: 'DeonfsTfdcqn97f7JKlTxhzfOeNbAWz8'
};
